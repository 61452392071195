import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

export default function CallMe() {
  return (
    <Layout>
      <Footer />
    </Layout>
  )
}
